
























import Vue from 'vue';
import IUiStringMessage from '@/models/uistring/IUiStringMessage';
import UiString from "@/components/uistring/UiString.vue";

const COPY_TEXT = "Copy HTML Source";
const COPIED_TEXT = "Copied!";

export default Vue.extend({
    name: 'uistring-midresult',
    props: {
        result: {
            type: Object as () => any,
        },
        mid: {
            type: Object as () => any,
        },
    },
    components: {
        UiString,
    },
    data: function() {
        return {
            copyText: COPY_TEXT
        }
    },
    computed: {
        uiHtmlMsg(): IUiStringMessage {
            const ret: IUiStringMessage = {
                id: Number(this.mid),
                message: String(this.result.html),
            };

            return ret;
        }
    },
    methods: {
        copyHtml() {
            let hiddenArea = this.$refs.htmltext as HTMLInputElement;
            hiddenArea.focus();
            hiddenArea.select();
            let success = document.execCommand('copy');
            if (success) {
                this.copyText = COPIED_TEXT;
                setTimeout(() => this.copyText = COPY_TEXT, 1000);
            } else {
                // console.log("KO");
            }
        },
        googleTranslate() {
            //  TODO get working
            let div = this.$refs.htmlArea as HTMLElement;
            let text = div.textContent;
            let url = `https://translate.google.com/#ko/en/${(text)}`;
            window.open(url);
        },
        selectText() {
            const entity = this.$refs.rawtext as HTMLInputElement;

            entity.focus();
            entity.select();
        }
    }
});
